import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home/:folder',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'manage-coupon',
    loadChildren: () =>
      import('./pages/manage-coupon/manage-coupon.module').then(
        (m) => m.ManageCouponPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-gift',
    loadChildren: () =>
      import('./pages/manage-gift/manage-gift.module').then(
        (m) => m.ManageGiftPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-business',
    loadChildren: () =>
      import('./pages/manage-business/manage-business.module').then(
        (m) => m.ManageBusinessPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'business-detail/:id',
    loadChildren: () =>
      import('./pages/business-details/business-details.module').then(
        (m) => m.BusinessDetailsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'contest-add',
    loadChildren: () =>
      import('./pages/contest-add/contest-add.module').then(
        (m) => m.ContestAddPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'create-quiz',
    loadChildren: () =>
      import('./pages/create-quiz/create-quiz.module').then(
        (m) => m.CreateQuizPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'view-quiz',
    loadChildren: () =>
      import('./pages/view-quiz/view-quiz.module').then(
        (m) => m.ViewQuizPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'contest-view',
    loadChildren: () =>
      import('./pages/contest-view/contest-view.module').then(
        (m) => m.ContestViewPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-quiz',
    loadChildren: () =>
      import('./pages/manage-quiz/manage-quiz.module').then(
        (m) => m.ManageQuizPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'gift-add',
    loadChildren: () =>
      import('./pages/gift-add/gift-add.module').then(
        (m) => m.GiftAddPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'gift-lists',
    loadChildren: () =>
      import('./pages/gift-lists/gift-lists.module').then(
        (m) => m.GiftListsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'gift-preview',
    loadChildren: () =>
      import('./pages/gift-preview/gift-preview.module').then(
        (m) => m.GiftPreviewPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'gift-edit/:giftId',
    loadChildren: () =>
      import('./pages/gift-edit/gift-edit.module').then(
        (m) => m.GiftEditPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'quiz-list/:tag',
    loadChildren: () =>
      import('./pages/quiz-list/quiz-list.module').then(
        (m) => m.QuizListPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-contest',
    loadChildren: () =>
      import('./pages/choose-contest/choose-contest.module').then(
        (m) => m.ChooseContestPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'gift-details/:giftId',
    loadChildren: () =>
      import('./pages/gift-details/gift-details.module').then(
        (m) => m.GiftDetailsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'contest-details/:spotplayContestId',
    loadChildren: () =>
      import('./pages/contest-details/contest-details.module').then(
        (m) => m.ContestDetailsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'view-business-participants',
    loadChildren: () =>
      import(
        './pages/view-business-participants/view-business-participants.module'
      ).then((m) => m.ViewBusinessParticipantsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'contest-view-participants',
    loadChildren: () =>
      import(
        './pages/contest-view-participants/contest-view-participants.module'
      ).then((m) => m.ContestViewParticipantsPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'coupon-redeem',
    loadChildren: () =>
      import('./pages/coupon-redeem/coupon-redeem.module').then(
        (m) => m.CouponRedeemPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'advertisement-add',
    loadChildren: () =>
      import('./pages/advertisement-add/advertisement-add.module').then(
        (m) => m.AdvertisementAddPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-coupon-redeem',
    loadChildren: () =>
      import('./pages/manage-coupon-redeem/manage-coupon-redeem.module').then(
        (m) => m.ManageCouponRedeemPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'coupon-details',
    loadChildren: () =>
      import('./pages/coupon-details/coupon-details.module').then(
        (m) => m.CouponDetailsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'coupon-view',
    loadChildren: () =>
      import('./pages/coupon-view/coupon-view.module').then(
        (m) => m.CouponViewPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'msregister',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },

  {
    path: 'ads-list',
    loadChildren: () =>
      import('./pages/ads-list/ads-list.module').then(
        (m) => m.AdsListPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'business-edit/:id',
    loadChildren: () =>
      import('./pages/business-edit/business-edit.module').then(
        (m) => m.BusinessEditPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'business-lists',
    loadChildren: () =>
      import('./pages/business-lists/business-lists.module').then(
        (m) => m.BusinessListsPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'contest-edit/:contestId',
    loadChildren: () =>
      import('./pages/contest-edit/contest-edit.module').then(
        (m) => m.ContestEditPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'contest-gift-add',
    loadChildren: () =>
      import('./pages/contest-gift-add/contest-gift-add.module').then(
        (m) => m.ContestGiftAddPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'manage-contest-gifts/:contestId',
    loadChildren: () =>
      import('./pages/manage-contest-gifts/manage-contest-gifts.module').then(
        (m) => m.ManageContestGiftsPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'business-branch-add',
    loadChildren: () =>
      import('./pages/business-branch-add/business-branch-add.module').then(
        (m) => m.BusinessBranchAddPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'current-billing-details',
    loadChildren: () =>
      import(
        './pages/current-billing-details/current-billing-details.module'
      ).then((m) => m.CurrentBillingDetailsPageModule),
      canActivate: [AuthGuardService],
  },
  {
    path: 'avail-subs-details',
    loadChildren: () =>
      import('./pages/avail-subs-details/avail-subs-details.module').then(
        (m) => m.AvailSubsDetailsPageModule
      ),
      canActivate: [AuthGuardService],
  },
  {
    path: 'terms-of-service',
    loadChildren: () =>
      import('./pages/terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServicePageModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyPageModule
      ),
  },
  {
    path: 'billed-plan-details',
    loadChildren: () => import('./pages/billed-plan-details/billed-plan-details.module').then( m => m.BilledPlanDetailsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
