/* eslint-disable @typescript-eslint/naming-convention */
export class Constants {
  //static GATEWAY_ENDPOINT = 'http://localhost:8080';
  //static GAME_URL = 'http://localhost:8100/';
  static GATEWAY_ENDPOINT = 'https://api.spotbay.in';
  static GAME_URL = 'https://spotbay.in';
  //static GATEWAY_ENDPOINT = 'https://sandbox-gateway.spotbay.co';
  static AUTH_SERVICE = Constants.GATEWAY_ENDPOINT + '/auth/';
  static CONTEST_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbaycontests/';
  static QUIZ_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbayquiz/';
  static BUSINESS_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbaybusiness/';
  static PROFILE_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbayprofile/';
  static GIFT_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbaygift/';

  static PARTICIPATION_SERVICE =
    Constants.GATEWAY_ENDPOINT + '/spotbayParticipation/';

  static AUTH_API = {
    login: Constants.AUTH_SERVICE + 'adminLogin',
    verify: Constants.AUTH_SERVICE + 'verify',
    skipotp: Constants.AUTH_SERVICE + 'skipotp',
  };

  static CONTEST_API = {
    allContests: Constants.CONTEST_SERVICE + 'allContests',
    contestsById:
      Constants.CONTEST_SERVICE + 'getContestsById/spotplayContestId',
    contestInfo: Constants.CONTEST_SERVICE + 'getContestsInfo?label=',
    getContestsById: Constants.CONTEST_SERVICE + 'getContestsById',
    saveBusinessAds: Constants.CONTEST_SERVICE + 'saveBrandAd',
    contestDeactivate: Constants.CONTEST_SERVICE + 'updateDeactivateStatus',
    addContest: Constants.CONTEST_SERVICE + 'createContest',
    refillGift: Constants.CONTEST_SERVICE + 'refillGift/giftId',
    getGiftInfo: Constants.CONTEST_SERVICE + 'getBrandPageAdInfo',
    allContestsByBusinessId:
      Constants.CONTEST_SERVICE + 'getAllContestsByBusinessId',
    updateContest: Constants.CONTEST_SERVICE + 'updateContest',
    deleteBrandAd: Constants.CONTEST_SERVICE + 'deleteBrandAd',
    viewAllBrandPageAdByBusiness:
      Constants.CONTEST_SERVICE + 'viewAllBrandPageAdByBusinessId',
    assignGiftWithScoreForContest:
      Constants.CONTEST_SERVICE + 'assignGiftWithScoreForContest',
    getContestGiftsById: Constants.CONTEST_SERVICE + 'getContestGiftInfo',
    getContestAllGiftsById:
      Constants.CONTEST_SERVICE + 'getAllContestGiftInfoByContestId',
  };

  static GIFT_API = {
    viewAllGifts: Constants.GIFT_SERVICE + 'viewAllGifts',
    viewAllGiftsByBusiness: Constants.GIFT_SERVICE + 'viewAllGiftsByBusinessId',
    saveGift: Constants.GIFT_SERVICE + 'addgift',
    viewGiftDetails: Constants.GIFT_SERVICE + 'viewgift',
    deleteGift: Constants.GIFT_SERVICE + 'deletegift',
    updateGift: Constants.GIFT_SERVICE + 'updategift',
  };

  static QUIZ_API = {
    allTags: Constants.QUIZ_SERVICE + 'getAllTags',
    quizByTag: Constants.QUIZ_SERVICE + 'getQuizByTag?tags=',
    deleteQuiz: Constants.QUIZ_SERVICE + 'deletequiz/',
    addQuiz: Constants.QUIZ_SERVICE + 'addquiz',
  };

  static BUSINESS_API = {
    saveBusiness: Constants.BUSINESS_SERVICE + 'addBranchDetails',
    updateBusinessBranch: Constants.BUSINESS_SERVICE + 'updateBranchDetails',
    updateBusinessBranchLogo: Constants.BUSINESS_SERVICE + 'updateBranchLogo',
    createAccount: Constants.BUSINESS_SERVICE + 'addBusiness',
    getBranchDetails: Constants.BUSINESS_SERVICE + 'getBranchDetails',
    getAllBranches: Constants.BUSINESS_SERVICE + 'getAllBranches',
    getSubscriptionPlanForBusiness:
      Constants.BUSINESS_SERVICE + 'getSubscriptionPlanByBusinessId',
    getBranchCount: Constants.BUSINESS_SERVICE + 'getBranchCount',
    getAllSubscriptionPlans:
      Constants.BUSINESS_SERVICE + 'getAllSubscriptionPlans',
    saveSelectedPlanForBusiness:
      Constants.BUSINESS_SERVICE + 'saveBusinessSubscriptionMap',
    setDefaultPlan: Constants.BUSINESS_SERVICE + 'saveDefaultPlan',
    getBillForBusiness: Constants.BUSINESS_SERVICE + 'getBillsByBusinessId',
  };

  static PARTICIPATION_API = {
    contestParticipation:
      Constants.PARTICIPATION_SERVICE + 'participationByContestId?contestId=',
  };
  static MESSAGES = {
    usernameValidationFailed: 'Enter a valid Username',
    passwordValidationFailed: 'Enter a valid Password',
    quizQuestionValidationFailed: 'Question cannot be empty',
    quizAnswerValidationFailed: 'Answer cannot be empty',
    quizOptionValidationFailed: 'Option cannot be empty',
  };

  static INNERPAGES = [
    '/create-quiz',
    '/view-quiz',
    '/quiz-list',
    '/gift-add',
    '/gift-lists',
    '/gift-details',
    '/business-lists',
    '/ads-list',
    '/advertisement-add',
    '/contest-add',
    '/contest-view',
    '/contest-select-gift',
    '/contest-gift-add',
    '/manage-contest',
    '/manage-gift',
    '/manage-business',
    '/manage-quiz',
    '/contest-details',
    '/contest-edit',
    '/gift-edit',
    '/business-detail',
    '/business-edit',
    '/manage-contest-gifts',
  ];
  static BACKMAP = {
    '/create-quiz': 'view-quiz',
    '/view-quiz': 'home',
    '/quiz-list': 'view-quiz',
    '/gift-lists': 'home',
    '/gift-add': 'gift-lists',
    '/gift-edit': 'gift-lists',
    '/gift-details': 'gift-lists',
    '/business-lists': 'manage-business',
    '/business-detail': 'business-lists',
    '/business-edit': 'business-lists',
    '/ads-list': 'home',
    '/advertisement-add': 'ads-list',
    '/contest-add': 'contest-view',
    '/contest-view': 'home',
    '/contest-details': 'contest-view',
    '/contest-edit': 'contest-view',
    '/contest-select-gift': 'contest-view',
    '/manage-contest-gifts': 'contest-view',
    '/contest-gift-add': 'contest-view',
    '/manage-contest': 'home',
    '/manage-gift': 'home',
    '/manage-business': 'home',
    '/manage-quiz': 'home',
  };
  static NOMENUPAGES = [
    '/register',
    '/msregister',
    '/login',
    '/terms-of-service',
  ];
}
